import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 商户区域分布情况
 */
export const transactionInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/transactions/transactionInfo',
        params
    })
}

/**
 * 交易兑付情况统计
 */
export const cashingStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/transactions/cashingStatistics',
        params
    })
}

/**
 * 预付交易新增趋势
 */
export const transactionAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/transactions/transactionAdd',
        params
    })
}

/**
 * 预付交易累计趋势
 */
export const transactionCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/transactions/transactionCumulative',
        params
    })
}

/**
 * 预付交易按城市/地区统计
 */
export const transactionCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/transactions/transactionCityStatistics',
        params
    })
}

/**
 * 预付码按领域/行业统计
 */
export const transactionIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/transactions/transactionIndustryStatistics',
        params
    })
}
